// import * as React from 'react';
import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'

import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardMedia from '@mui/material/CardMedia'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Collapse from '@mui/material/Collapse'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { red } from '@mui/material/colors'
import FavoriteIcon from '@mui/icons-material/Favorite'
import ShareIcon from '@mui/icons-material/Share'
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter'
import EditIcon from '@mui/icons-material/Edit'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import SaveIcon from '@mui/icons-material/Save'
import SearchIcon from '@mui/icons-material/Search'
import PersonSearchIcon from '@mui/icons-material/PersonSearch'
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera'
import PrintIcon from '@mui/icons-material/Print'
import Modal from '@mui/material/Modal'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Select from './../componentes/functions/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import FormHelperText from '@mui/material/FormHelperText'
import { Hidden } from '@mui/material'
import DenseTable from '../componentes/functions/CustomizedTables'
import Notificaciones from '../componentes/functions/Notificaciones'
import TextField from '@mui/material/TextField'
import LoadingButton from '@mui/lab/LoadingButton'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import Generos from './../data/generos.json'
import Documentos from './../data/documentos.json'
import Disciplinas from './../data/disciplinas.json'
import Sino from './../data/sino.json'
import { nanoid } from 'nanoid'
import Kardex from './../componentes/kardex'
import APIRest from './../services/conexion'

const Input = styled('input')({
  display: 'none',
})
const style = {
  position: 'relative',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '580px',
  minWidth: '350px',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 50,
  p: 2,
}

export default function Home(props) {
  const [open, setOpen] = React.useState(false)
  const modalOpen = () => setOpen(true)
  const modalClose = () => setOpen(false)
  const [modalBuscar, setModalBuscar] = useState(false)
  const [registros, setRegistros] = useState([])
  const [registrobuscado, setRegistrobuscado] = useState([])
  const [notificacion, setNotificacion] = useState({
    isOpen: false,
    message: '',
    type: '',
  })
  const [isLoading, setIsLoading] = useState(false)
  const [campos, setCampos] = useState({
    id: '',
    foto: '',
    ci: '',
    nombres: '',
    apellidos: '',
    genero: '',
    equipo: '',
    fechalugarnac: '',
    documento: '',
    equipo2020: '',
    vacunado: '',
    disciplina: '',
    obs: '',
  })

  const cabeceraTable = [
    { col: '' },
    { col: 'C.I.' },
    { col: 'Nombres Apellido' },
    { col: 'Disciplina' },
    { col: 'Equipo' },
  ]

  const [file, setFile] = useState()
  const subiendoImg = (e) => {
    // console.log(e.target.files)
    setFile(URL.createObjectURL(e.target.files[0]))
  }

  const abrirCerrarModalBuscar = () => {
    setModalBuscar(!modalBuscar)
    setRegistrobuscado(null)
  }

  const jugadoresGet = () => {
    fetch('http://127.0.0.1:3333/api/v1/jugadores/index')
      .then((res) => res.json())
      .then((result) => {
        setRegistros(result)
      })
  }

  const cerrarModal = () => {
    setIsLoading(false)
    setOpen(false)
  }

  useEffect(() => {
    getVerdatos()
    // fetch("http://127.0.0.1:3333/api/v1/jugadores/index")
    //   .then(res => res.json())
    //   .then(
    //     (result) => {
    //       setRegistros(result);
    //       console.log(result);
    //     },
    //     (error) => {
    //       alert("error.code");
    //       // console.log(error);
    //     }
    //   ).catch(error => {
    //     alert("EROR");
    //     // console.log(error.code);
    //     setNotificacion({
    //         isOpen: true,
    //         message: 'Revisa tu conexión a internet, no estas conectado.',
    //         type: 'success',
    //         duration: 10000
    //     })
    //   })
  }, [])

  const getVerdatos = async (values, props) => {
    await APIRest.get('/api/v1/jugadores/index/')
      .then((response) => {
        setRegistros(response.data)
        // console.log(response.data);
      })
      .catch((error) => {
        setNotificacion({
          isOpen: true,
          message:
            'El servidor no responde, probablemente no tienes internet activado.',
          type: 'warning',
          duration: 10000,
        })
        console.log(error)
      })
    // console.log(JSON.stringify(values), null, 2)
    // alert(JSON.stringify(values), null, 2)
  }

  const getVerundato = async (values, props) => {
    setIsLoading(true)
    await APIRest.post('/api/v1/jugadores/buscaJug/', values)
      .then((response) => {
        setIsLoading(false)
        if (response.data['f_ci']) {
          setRegistrobuscado(response.data)
          console.log('AQUI: ' + response.data['f_ci'])
        } else {
          setNotificacion({
            isOpen: true,
            message: 'El jugador no esta registrado en el sistema.',
            type: 'info',
            duration: 10000,
          })
        }
      })
      .catch((error) => {
        setIsLoading(false)
        setNotificacion({
          isOpen: true,
          message: 'El servidor no responde, revisa tu conexion a internet.',
          type: 'info',
          duration: 10000,
        })
        console.log(error)
      })
    // console.log(JSON.stringify(values), null, 2)
    // alert(JSON.stringify(values), null, 2)
  }

  const submitJugador = async (values, props) => {
    setIsLoading(true)
    // const newnombre = nanoid()
    // values.foto = newnombre+'';
    let data = new FormData()
    data.append('foto', values.foto)
    // fetch('http://127.0.0.1:3333/api/v1/jugadores/cargarFoto',{
    //   method: "post",
    //   headers: new Headers({Accept: "application/json"}),
    //   body: data,
    // })
    // .then((response) => response.json())
    // .then((data) => console.log(data))
    // .catch((error) => console.log(error))

    // await APIRest.post('/api/v1/jugadores/store/',{values})
    fetch('http://127.0.0.1:3333/api/v1/jugadores/store/', {
      method: 'post',
      headers: new Headers({ Accept: 'application/json' }),
      body: data,
    })
      .then((response) => {
        setNotificacion({
          isOpen: true,
          message: 'Bienvenido ' + values.nombres + ' ' + values.apellidos,
          type: 'success',
          duration: 10000,
        })
        // props.resetForm();
        setIsLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setNotificacion({
          isOpen: true,
          message:
            values.nombres +
            ' ' +
            values.apellidos +
            ' ya estas registrado en el sistema ',
          type: 'info',
          duration: 8000,
        })
        setIsLoading(false)
      })
    console.log(JSON.stringify(values), null, 2)
    // props.resetForm()
  }

  const validaJugador = Yup.object().shape({
    foto: Yup.string().required('Elegir foto'),
    nombres: Yup.string()
      .min(3, 'Debe tener más de 3 caracteres')
      .required('No puede quedar vacio'),
    apellidos: Yup.string()
      .min(3, 'Debe tener más de 3 caracteres')
      .required('No puede quedar vacio'),
    ci: Yup.string()
      .min(5, 'Debe tener más de 5 caracteres')
      .required('No puede quedar vacio'),
    genero: Yup.string().required('No puede quedar vacio'),
    equipo: Yup.string()
      .min(5, 'Debe tener más de 5 caracteres')
      .required('No puede quedar vacio'),
    fechalugarnac: Yup.string()
      .min(5, 'Debe tener más de 5 caracteres')
      .required('No puede quedar vacio'),
    documento: Yup.string().required('No puede quedar vacio'),
    equipo2020: Yup.string()
      .min(5, 'Debe tener más de 5 caracteres')
      .required('No puede quedar vacio'),
    vacunado: Yup.string().required('No puede quedar vacio'),
    disciplina: Yup.string().required('No puede quedar vacio'),
    obs: Yup.string()
      .min(7, 'Debe tener más de 7 caracteres')
      .required('No puede quedar vacio'),
  })

  const validaJugadorbuscado = Yup.object().shape({
    ci: Yup.string()
      .min(5, 'Debe tener más de 5 caracteres')
      .required('No puede quedar vacio'),
  })

  const formRegistrar = (
    <React.Fragment>
      <h2>Registro nuevo</h2>
      <Formik
        initialValues={campos}
        validationSchema={validaJugador}
        onSubmit={(values) => {
          setIsLoading(true)
          let data = new FormData()
          data.append('foto', values.foto)
          data.append('ci', values.ci)
          data.append('nombres', values.nombres)
          data.append('apellidos', values.apellidos)
          data.append('genero', values.genero)
          data.append('equipo', values.equipo)
          data.append('fechalugarnac', values.fechalugarnac)
          data.append('documento', values.documento)
          data.append('equipo2020', values.equipo2020)
          data.append('vacunado', values.vacunado)
          data.append('disciplina', values.disciplina)
          data.append('obs', values.obs)
          return fetch(
            'https://apinode.infnovanet.com/api/v1/jugadores/store',
            {
              method: 'post',
              headers: new Headers({ Accept: 'application/json' }),
              body: data,
            },
          )
            .then((response) => {
              response.json()
              getVerdatos()
              cerrarModal()
              setNotificacion({
                isOpen: true,
                message:
                  'Bienvenido ' + values.nombres + ' ' + values.apellidos,
                type: 'success',
                duration: 10000,
              })
            })
            .then((data) => console.log(data))
            .catch((error) => console.log(error))
        }}
      >
        {(props) => (
          <Form>
            <Grid container spacing={1}>
              <Box>
                <small>
                  Nota: Registrarse correctamente sin errores, revisar antes de
                  guardar sus datos.
                </small>
              </Box>

              {/* <h2>Add Image:</h2> */}
              {/* <input type="file" onChange={subiendoImg} /> */}

              <Grid item xs={2} md={2}>
                <img width="100%" src={file} />
                <label htmlFor="icon-button-file">
                  <Input
                    required
                    accept="image/*"
                    id="icon-button-file"
                    type="file"
                    name="foto"
                    onChange={(event) => { props.setFieldValue('foto', event.target.files[0]); subiendoImg(event)}}
                  />
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                  >
                    <PhotoCameraIcon />
                  </IconButton>
                </label>
              </Grid>
              <Grid item xs={10} md={5}>
                <Field
                  as={TextField}
                  name="nombres"
                  label="Nombres"
                  variant="outlined"
                  margin="dense"
                  autoFocus
                  disabled={isLoading}
                  error={props.errors.nombres && props.touched.nombres}
                  helperText={<ErrorMessage name="nombres" />}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <Field
                  as={TextField}
                  name="apellidos"
                  label="Apellidos"
                  error={props.errors.apellidos && props.touched.apellidos}
                  variant="outlined"
                  margin="dense"
                  disabled={isLoading}
                  helperText={<ErrorMessage name="apellidos" />}
                  fullWidth
                  required
                />
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={6} md={6}>
                <Field
                  as={TextField}
                  name="ci"
                  label="Número de C.I."
                  error={props.errors.ci && props.touched.ci}
                  variant="outlined"
                  margin="dense"
                  disabled={isLoading}
                  helperText={<ErrorMessage name="ci" />}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <Select
                  name="genero"
                  label="Género"
                  margin="dense"
                  required
                  disabled={isLoading}
                  options={Generos}
                />
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={5} md={6}>
                <Field
                  as={TextField}
                  name="equipo"
                  label="Equipo"
                  error={props.errors.equipo && props.touched.equipo}
                  variant="outlined"
                  margin="dense"
                  disabled={isLoading}
                  helperText={<ErrorMessage name="equipo" />}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={7} md={6}>
                <Field
                  as={TextField}
                  name="fechalugarnac"
                  label="Fecha lugar de nacimiento"
                  error={
                    props.errors.fechalugarnac && props.touched.fechalugarnac
                  }
                  variant="outlined"
                  margin="dense"
                  disabled={isLoading}
                  helperText={<ErrorMessage name="fechalugarnac" />}
                  fullWidth
                  required
                />
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={6} md={6}>
                <Select
                  name="documento"
                  label="Documento de acredite"
                  margin="dense"
                  required
                  disabled={isLoading}
                  options={Documentos}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <Field
                  as={TextField}
                  name="equipo2020"
                  label="Fecha de registro"
                  error={props.errors.equipo2020 && props.touched.equipo2020}
                  variant="outlined"
                  margin="dense"
                  placeholder="día-mes-año"
                  disabled={isLoading}
                  helperText={<ErrorMessage name="equipo2020" />}
                  fullWidth
                  required
                />
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={6} md={6}>
                <Select
                  name="vacunado"
                  label="Vacunado Covid19?"
                  margin="dense"
                  required
                  disabled={isLoading}
                  options={Sino}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <Select
                  name="disciplina"
                  label="Disciplina"
                  margin="dense"
                  required
                  disabled={isLoading}
                  options={Disciplinas}
                />
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={12} md={12}>
                <Field
                  as={TextField}
                  name="obs"
                  label="Observaciones"
                  error={props.errors.obs && props.touched.obs}
                  variant="outlined"
                  margin="dense"
                  disabled={isLoading}
                  helperText={<ErrorMessage name="obs" />}
                  fullWidth
                  required
                />
              </Grid>
            </Grid>

            <LoadingButton
              color="secondary"
              type="submit"
              loading={isLoading}
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="contained"
              disabled={props.errors.foto ? true : false}
            >
              Guardar datos
            </LoadingButton>
            <Button
              sx={{ ml: 2 }}
              onClick={() => cerrarModal()}
              variant="outlined"
              disabled={isLoading}
            >
              Cerrar
            </Button>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  )

  const formBuscar = (
    <React.Fragment>
      <h2>Buscar jugador</h2>
      <Formik
        initialValues={campos}
        validationSchema={validaJugadorbuscado}
        onSubmit={getVerundato}
      >
        {(props) => (
          <Form>
            <Grid container spacing={1}>
              <Grid item xs={12} md={12}>
                <Field
                  as={TextField}
                  name="ci"
                  label="Número de C.I."
                  error={props.errors.ci && props.touched.ci}
                  variant="outlined"
                  margin="dense"
                  disabled={isLoading}
                  helperText={<ErrorMessage name="ci" />}
                  fullWidth
                  required
                />
              </Grid>
            </Grid>

            <LoadingButton
              color="secondary"
              type="submit"
              loading={isLoading}
              loadingPosition="start"
              startIcon={<SearchIcon />}
              variant="contained"
              disabled={props.errors.foto ? true : false}
            >
              Buscar
            </LoadingButton>
            <Button
              sx={{ ml: 2 }}
              onClick={() => abrirCerrarModalBuscar()}
              variant="outlined"
              disabled={isLoading}
            >
              Cerrar
            </Button>
          </Form>
        )}
      </Formik>
      {registrobuscado && <Kardex datospersonales={registrobuscado} />}
    </React.Fragment>
  )

  return (
    <React.Fragment>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            {/* <video loop autoPlay controls width="100%">
                     <source src="./images/publi.mp4" type="video/mp4" ></source>
                  </video> */}
            <img src="./images/img1.jpg" alt="CyberMAQ" width="100%" />
            {/* <iframe src="https://www.facebook.com/plugins/video.php?height=308&href=https%3A%2F%2Fwww.facebook.com%2Fcampeonato.escarenacbba%2Fvideos%2F1164597300610875%2F&show_text=true&width=560&t=0" width="100%" height="423" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe> */}

            <br />
            <br />
            {/* <Button
                      href="https://campeonato.cybermaq.com/informacion.zip"
                      target="_blank"
                      variant="contained"
                      color="secondary"
                      size="large"
                      fullWidth
                      startIcon={<CloudDownloadIcon />}>
                      DESCARGAR INFORMACIÓN
                  </Button> <br /><br /> */}
            <Hidden mdDown>
              <DenseTable cabecera={cabeceraTable} registros={registros} />
            </Hidden>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card sx={{ maxWidth: '100%' }}>
              <CardHeader
                avatar={
                  <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                    <FitnessCenterIcon />
                  </Avatar>
                }
                action={
                  <IconButton aria-label="Compartir">
                    <ShareIcon />
                  </IconButton>
                }
                title="Página web de inscripción"
                subheader="Campeonato 2023"
              />
              <CardMedia
                component="img"
                height="100%"
                image="./images/conv.jpg"
                alt="CyberMAQ"
              />
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  Nota: Registrarse correctamente sin errores, revisar antes de
                  guardar sus datos. <br /><br />
                  <Button
                    onClick={modalOpen}
                    variant="contained"
                    color="success"
                    size="large"
                    fullWidth
                    startIcon={<EditIcon />}
                  >
                    REGISTRarse
                  </Button>
                  <br />
                  <br />
                  <br />
                  <Button
                    onClick={abrirCerrarModalBuscar}
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                    startIcon={<PersonSearchIcon />}
                  >
                    BUSCAR JUGADOR PARA KARDEX
                  </Button>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>

      <Modal
        open={open}
        onClose={modalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>{formRegistrar}</Box>
      </Modal>

      <Modal
        open={modalBuscar}
        onClose={abrirCerrarModalBuscar}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>{formBuscar}</Box>
      </Modal>

      <Notificaciones notify={notificacion} setNotify={setNotificacion} />
    </React.Fragment>
  )
}
