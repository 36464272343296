import React, {useState} from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import MuiAlert from '@mui/material/Alert';
import DownloadIcon from '@mui/icons-material/Download';
import jsPDF from 'jspdf'
import QRCode from 'qrcode';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Kardex(props) {
  const {datospersonales} = props;
  const [codqr, setCodqr] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  QRCode.toDataURL(datospersonales.f_nombres+' '+datospersonales.f_apellidos+' C.I.:'+datospersonales.f_ci)
    .then(img => {
      setCodqr(img);
      //  console.log(img);
  })

    const generatePDF =()=>{
      setIsLoading(true)
      const doc = new jsPDF('p', '', 'letter');

      var imgPlantilla = ('./images/tarjetaDep.jpg');
      doc.addImage(imgPlantilla, 'JPG | PNG | JPEG', 1, 1, 214, 279);

      var imgQR = codqr;
      doc.addImage(imgQR, 'JPG | PNG | JPEG', 175, 89, 34, 34)
      doc.addImage(imgQR, 'JPG | PNG | JPEG', 4, 253, 23, 23)
      doc.addImage(imgQR, 'JPG | PNG | JPEG', 111, 253, 23, 23)

      var foto = 'https://apinode.infnovanet.com/subidos/'+datospersonales.f_foto;
      doc.addImage(foto, 'JPG | PNG | JPEG', 178, 51, 28, 28)
      doc.addImage(foto, 'JPG | PNG | JPEG', 4, 229, 22, 22)
      doc.addImage(foto, 'JPG | PNG | JPEG', 111, 229, 22, 22)

      doc.setFontSize(15)
      doc.addFont("/font/Oswald.ttf", "Oswald", "normal");

      doc.setFont("Oswald");
      doc.text(57, 58, datospersonales.f_nombres+' '+datospersonales.f_apellidos)
      doc.text(20, 65, datospersonales.f_ci)
      if (datospersonales.f_genero === 'M') {
        var gene = 'Masculino';
      } else {
        var gene = 'Femenino';
      }
      doc.text(102, 65, gene)
      doc.text(28, 72, datospersonales.f_equipo)
      doc.text(35, 79, datospersonales.f_disciplina)
      doc.text(71, 86, datospersonales.f_fechalugarnac)
      doc.text(69, 93, datospersonales.f_documento)
      doc.text(50, 100, datospersonales.f_equipo2020)
      if (datospersonales.f_vacunado === 'S') {
        var vacu = 'Si';
      } else {
        var vacu = 'No';
      }
      doc.text(43, 107, vacu)
      doc.text(12, 120, datospersonales.f_obs)

      doc.setFontSize(12)
      doc.text(31, 241, datospersonales.f_nombres+' '+datospersonales.f_apellidos)
      doc.text(31, 251, datospersonales.f_equipo)
      doc.text(31, 261, datospersonales.f_disciplina)
      doc.text(31, 271, datospersonales.f_fechalugarnac)

      doc.text(138, 241, datospersonales.f_nombres+' '+datospersonales.f_apellidos)
      doc.text(138, 251, datospersonales.f_equipo)
      doc.text(138, 261, datospersonales.f_disciplina)
      doc.text(138, 271, datospersonales.f_fechalugarnac)

      doc.save(datospersonales.f_nombres+' '+datospersonales.f_apellidos+' CyberMAQ.pdf')
      setIsLoading(false)
    }

      return (
         <React.Fragment> <hr />
          <Alert severity="success"> {datospersonales.f_nombres +' '+datospersonales.f_apellidos}
            <LoadingButton
              sx={{ ml: 2 }}
              onClick={()=>generatePDF()}
              color="warning"
              type='submit'
              loading={isLoading}
              loadingPosition="start"
              startIcon={<DownloadIcon />}
              variant="contained"
              disabled={isLoading}
            >
              Descargar kardex
            </LoadingButton>
          </Alert>
         </React.Fragment>
      );

}
// export default Kardex;