import React from 'react'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';

function TransitionLeft(props) {
  return <Slide {...props} direction="left" />;
}
function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}
function TransitionRight(props) {
  return <Slide {...props} direction="right" />;
}
function TransitionDown(props) {
  return <Slide {...props} direction="down" />;
}

export default function Notificaciones(props) {
   const { notify, setNotify } = props;
  //  const classes = useStyles()

   const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
         return;
      }
      setNotify({
            ...notify,
            isOpen: false
      })
   }

   return (
      <Snackbar
            open={notify.isOpen}
            autoHideDuration={notify.duration}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            TransitionComponent={TransitionDown}
            onClose={handleClose}>
            <MuiAlert
                sx={{ width: '100%' }}
                variant="filled"
                severity={notify.type ? notify.type: 'info'}
                onClose={handleClose}>
                {notify.message}
            </MuiAlert>
      </Snackbar>
   )
}
