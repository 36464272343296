import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Navbar from './componentes/Navbar'
import Footer from './componentes/Footer';
import Home from './jugadores/Home';
export default function App() {
  return (
    <Router>
      <Navbar />
      <Home />
      <Footer />
    </Router>
  );
}