import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


export default function CustomizedTables(props) {
  const { cabecera, registros } = props;
  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            {
              cabecera.map((c) => (
                <StyledTableCell key={c.col}> {c.col} </StyledTableCell>
              ))
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {registros.map((row) => (
            <StyledTableRow key={row.id}>
              <StyledTableCell component="th" scope="row">
                <Avatar alt="CyberMAQ" src={'https://apinode.infnovanet.com/subidos/'+row.f_foto} />
              </StyledTableCell>
              <StyledTableCell>
                {row.f_ci}
              </StyledTableCell>
              <StyledTableCell>
                {row.f_nombres} {row.f_apellidos}
              </StyledTableCell>
              <StyledTableCell align="right">{row.f_disciplina}</StyledTableCell>
              <StyledTableCell align="right">{row.f_equipo}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}